import types from './types';
import {combineReducers} from 'redux';

const sidebarInitialState = {isOpen: true};

const ssnMaskInitialState = {isMasked: true};

const ssnMaskReducer = (state = ssnMaskInitialState, action) => {
  switch (action.type) {
    case types.SET_SSNMASK:
      return {
        ...state,
        isMasked: action.payload
      };
    default:
      return state;
  }
}

const sidebarReducer = (state = sidebarInitialState, action) => {
  switch (action.type) {
    case types.SET_SIDEBAR:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const loadingPanelInitialState = {
  instances: 0
};

const loadingPanelReducer = (state = loadingPanelInitialState, action) => {
  switch (action.type) {
    case types.LAYOUT_SHOW_LOADING_PANEL:
      return {
        ...state,
        instances: state.instances + action.payload
      };
    case types.LAYOUT_HIDE_LOADING_PANEL:
      return {
        ...state,
        instances: Math.max(0, state.instances + action.payload)
      };
    case types.LAYOUT_RESET_LOADING_PANEL:
      return {
        ...state,
        instances: action.payload
      };
    default:
      return state;
  }
};

const currentUserOptionsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.USER_OPTIONS_ADD:
      const currentOptions = {...state};
      if (!currentOptions[action.payload.pathname]) {
        currentOptions[action.payload.pathname] = {};
      }
      currentOptions[action.payload.pathname][action.payload.id] = action.payload.option;
      return {
        ...currentOptions
      };
    case types.USER_OPTIONS_CLEAR:
      return action.payload;
    default:
      return state;
  }
};

const whatsNewReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SET_WHATS_NEW:
      return action.payload;
    default:
      return state;
  }
};

const broadcastReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SET_BROADCAST:
      return action.payload;
    default:
      return state;
  }
};

const layoutReducers = combineReducers({
  sidebar: sidebarReducer,
  loadingPanel: loadingPanelReducer,
  currentUserOptions: currentUserOptionsReducer,
  ssnMask: ssnMaskReducer,
  whatsNew: whatsNewReducer,
  broadcast: broadcastReducer
});

export default layoutReducers;