import axios from 'axios';
import actions from './actions';

const setSidebar = actions.setSidebar;

const showLoadingPanel = actions.showLoadingPanel;

const hideLoadingPanel = actions.hideLoadingPanel;

const resetLoadingPanel = actions.resetLoadingPanel;

const addUserOption = actions.addUserOption;

const clearUserOptions = actions.clearUserOptions;

const setSSNMask = actions.setSSNMask;

const loadWhatsNew = () => dispatch => {
  axios.get('/v1/settings/documents/whatsnew')
  .then(res => {
    dispatch(actions.setWhatsNew(res.data));
  })
  .catch(err => {
    console.error(err);
    dispatch(actions.setWhatsNew(false));
  });
};

const loadBroadcastItems = () => dispatch => {
  axios.get('/v1/settings/texts?collection=Broadcast')
  .then(res => {
    dispatch(actions.setBroadcast(res.data.map(standardText => standardText.text)));
  })
  .catch(err => {
    console.error(err);
    dispatch(actions.setBroadcast([]));
  });
};

export default {
  setSidebar,
  showLoadingPanel,
  hideLoadingPanel,
  resetLoadingPanel,
  addUserOption,
  clearUserOptions,
  setSSNMask,
  loadWhatsNew,
  loadBroadcastItems
};