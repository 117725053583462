import React from 'react';
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from 'reactstrap'
import {connect} from 'react-redux';
import ToggleMaskContainer from './ToggleMaskContainer';

const authenticatedLinksComponent = props => {
  const {whatsNew, isAuditor, onLogoutClicked, auth: {user}} = props;
  return (
      <div className="top-nav d-flex flex-row">
        {!isAuditor && !!whatsNew && !!whatsNew.contents && (!user.whatsNewVersionsViewed || !user.whatsNewVersionsViewed.includes(whatsNew.version)) &&
        <div className={'mr-3 ml-1'}>
          <Link to={'/whatsnew'}>
            <i className="far fa-gift" id='whatsnew'/>
          </Link>
          <UncontrolledTooltip
              placement={'bottom'}
              target={'whatsnew'}
              container={'#root'}
          >
            What's New?
          </UncontrolledTooltip>
        </div>
        }
        <div>
          <ul className="nav">
            <li className="dropdown">
              <button
                  data-toggle="dropdown"
                  className="dropdown-toggle nav-link username btn btn-link btn-sm p-0 border-0"
              >
                <i className="fas fa-user-circle"/> {user.firstName} {user.lastName}
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {!isAuditor &&
                <>
                  <Link to="/user/profile" className="dropdown-item">
                    <i className="fas fa-user"/>My Profile
                  </Link>
                  <ToggleMaskContainer/>
                </>
                }
                <Link to="/" className="dropdown-item" onClick={onLogoutClicked}>
                  <i className="fas fa-sign-out-alt"/> Log Out
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
  )
};

const mapStateToProps = state => ({
  auth: state.auth,
  whatsNew: state.layout.whatsNew
});

export default connect(mapStateToProps)(authenticatedLinksComponent);