import types from './types';

const setSSNMask = selection => ({
  type: types.SET_SSNMASK,
  payload: selection
})

const setSidebar = options => ({
  type: types.SET_SIDEBAR,
  payload: options
});

/**
 * Action to show the Loading Panel and increase the instance count by 1
 * @returns {{type: string, payload: number}}
 */
const showLoadingPanel = () => ({
  type: types.LAYOUT_SHOW_LOADING_PANEL,
  payload: 1
});

/**
 * Action to hide the Loading Panel and decrease the instance count by 1
 * @returns {{type: string, payload: number}}
 */
const hideLoadingPanel = () => ({
  type: types.LAYOUT_HIDE_LOADING_PANEL,
  payload: -1
});

const resetLoadingPanel = () => ({
  type: types.LAYOUT_RESET_LOADING_PANEL,
  payload: 0
});

const addUserOption = (pathname, id, option) => ({
  type: types.USER_OPTIONS_ADD,
  payload: {pathname, id, option}
});

const clearUserOptions = () => ({
  type: types.USER_OPTIONS_CLEAR,
  payload: {}
});

const setWhatsNew = whatsNew => ({
  type: types.SET_WHATS_NEW,
  payload: whatsNew
});

const setBroadcast = broadcastItems => ({
  type: types.SET_BROADCAST,
  payload: broadcastItems
});

export default {
  setSidebar,
  showLoadingPanel,
  hideLoadingPanel,
  resetLoadingPanel,
  addUserOption,
  clearUserOptions,
  setSSNMask,
  setWhatsNew,
  setBroadcast
};